.firstCon{
    position: relative;
    display: flex;
    justify-content: center;
}

.firstImgCon img{
    width: 100vw;
    height: 20rem;
    object-fit: cover;
}
.detailCon{
    background-color: white;
    position: absolute;
    top: 16rem;
    width: 90vw;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}

.slipCon{
    display: flex;
    justify-content: space-between;
    margin: 3% 10%;
}

.nameCon{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    width: 70px;
}

.name{
    font-size: 15px;
    font-weight: 400;
    color: grey;
}

.Subname{
    font-size: 15px;
    font-weight: 600;
}

.line{
    height: 1px;
    background-color: lightgrey;
    margin: 0 10%;
}

.descriptionCon{
    display: flex;
    margin: 3% 10%;
}

.desTitle{
    width: 70%;
    font-size: 20px;
    font-weight: 600;
}

.desPara{
    font-size: 15px;
    line-height: 25px;
}

.ssContainer{
    margin-top: 17rem;
    display: flex;
    flex-wrap: wrap;
}

.ssCon{
    background-color: #f3f4f7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5% 0;
    border-radius: 10px;
    
}

.img1{
    width: 90%;
    border-radius: 5px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}
.img2{
    width: 80%;
    border-radius: 5px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}

.ssCon1{
    width: 100%;
}

.ssCon2{
    width: 50%;
}

.ssCon3{
    width: 50%;
}

.ssCon4{
    /* margin-left: auto; */
    width: 60%;
}

.ssCon5{
    width: 60%;
}

.footerCon{
    margin-top: 17vh;
}

.ssContainer1{
    display: flex;
    margin: 5vh 7.5vw;
}

.ssContainer2{
    display: flex;
    gap: 5vw;
    margin: 5vh 7.5vw;
}

.ssContainer3{
    display: flex;
    gap: 5vw;
    margin: 5vh 7.5vw;
}

.viewTitle{
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-top: 15vh;
}

@media (max-width: 990px) {
    .firstImgCon img{
        height: 10rem;
    }

    .detailCon{
        top: 9rem;
        width: 95vw;
    }
    
    .slipCon{
        flex-wrap: wrap;
    }
    
    .nameCon{
        width: 45%;
        margin: 10px 0;
    }
    
    .name{
        font-size: 13px;
    }
    
    .Subname{
        font-size: 13px;
    }
    
    .descriptionCon{
        display: flex;
        margin: 3% 10%;
        flex-direction: column;
        gap: 10px;
    }
    
    .desTitle{
        font-size: 18px;
    }
    
    .desPara{
        font-size: 13px;
        line-height: 20px;
    }
    
    .ssContainer{
        margin-top: 25rem;
    }
    
    .ssCon{
        padding: 5% 0;
        border-radius: 10px;
        
    }
    
    .img1{
        width: 90%;
    }
    .img2{
        width: 90%;
    }
    
    .ssCon1{
        width: 100%;
    }
    
    .ssCon2{
        width: 100%;
    }
    
    .ssCon3{
        width: 100%;
    }
    
    .ssCon4{
        /* margin-left: auto; */
        width: 100%;
    }
    
    .ssCon5{
        width: 100%;
    }
    
    .footerCon{
        margin-top: 10vh;
    }
    
    .ssContainer1{
        display: flex;
        margin: 5vh 2.5vw;
    }
    
    .ssContainer2{
        flex-direction: column;
        gap: 5vh;
        margin: 5vh 2.5vw;
    }
    
    .ssContainer3{
        flex-direction: column;
        gap: 5vh;
        margin: 5vh 2.5vw;
    }

    .viewTitle{
        margin-top: 10vh;
        font-size: 25px;
    }
}