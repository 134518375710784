.container{
    display: flex;
    position: relative;
}

.workCon{
    display: flex;
    width: 97vw;
    flex-direction: row-reverse;
}
.background{
    width: 40%;
    height: 90vh;
    margin-right: -30px;
    border-radius: 20px 0 0 20px;
}

    /* background-color: rgba(62, 156, 245, 1); */


.descriptionCon{
    width: 60%;
    display: flex;
    align-items: center;
}

.details{
    margin-left: 8vw;
    width: 30vw;
}

.title{
    font-size: 30px;
    font-weight: 700;
}

.subtitle{
    margin-top: 3vh;
    font-size: 20px;
    font-weight: 700;
}

.para{
    margin-top: 3vh;
    font-size: 20px;
}

.mainCon{
    display: flex;
    position: absolute;
    right: 26vw;
    top: 7.5rem;
    z-index: 10;
}

.clientContainer{
    display: flex;
    flex-direction: column;
    border: 3px solid black;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px;
    width: 16rem;
    height: 20rem;
    background-color: white;
}
.logoCon{
    margin-top: 5px;
    height: 4rem;
    padding: 10px;
    overflow: hidden;
}
.logo{
    /* width: 50px; */
    height: 50px;
}

.clientdescription{
    font-size: 15px;
    height: 9rem;
    font-weight: 450;
    color: rgb(50, 50, 50);
}

.nameCon{
    display: flex;
    align-items: center;
}

.nameCon img{
    object-fit: cover;
    border-radius: 50%;
    height: 60px;
    width: 60px;
}

.username{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.username h5{
    font-size: 15px !important;
    color: black;
    margin-top: 10px;
}

.username p{
    color: grey;
    font-size: 15px;
    margin-top: 5px;
}

.BtnCon{
    position: absolute;
    right: 36vw;
    top: 31rem;
    display: flex;
    gap: 5px;
}

.btn{
    height: 13px !important;
    width: 13px !important ;
    border-radius: 50%;
    border: 1px solid black;
    background-color: white;
    cursor: pointer;
}

.activeBtn{
    height: 13px !important;
    width: 13px !important ;
    border-radius: 50%;
    border: 1px solid black;
    background-color: black;
    cursor: pointer;
}

.BackCon{
    position: absolute;
    right: 10vw;
    top: -2rem;
    z-index: 15;
    /* display: none; */
}

.BackCon img{
    width: 20rem;
}


@media (max-width: 990px) {
    .container{
        align-items: center;
        justify-content: center;
    }

    .workCon{
        flex-direction: column-reverse;
        width: 100vw;
    }
    .background{
        width: 100%;
        height: 20rem;
        margin-right: 0px;
        margin-top: 20vh;
        border-radius: 20px 20px 0 0;
    }
    
        .descriptionCon{
            width: 100%;
            display: flex;
            justify-content: center;
        }
        
        .details{
            margin: 0;
            width: 95vw;
        }
        
        .title{
            font-size: 25px;
            text-align: center;
        }
        
        .subtitle{
            margin-top: 1vh;
            font-size: 18px;
            text-align: center;
        }
        
        .para{
            margin-top: 2vh;
            font-size: 18px;
            text-align: center;
        }
    
    .mainCon{
        right: 10vw;
        top: 18rem;
        width: 80vw;
    }
    
    .clientContainer{
        border: 2px solid black;
        border-radius: 10px;
        padding: 8px 20px;
        margin: 0px;
        width: 100%;
    }

    .logo{
        height: 45px;
    }
    
    .clientdescription{
        font-size: 14px;
    }
    
    .username h5{
        font-size: 14px !important;
    }
    
    .username p{
        font-size: 14px;
    }
    
    .BtnCon{
        right: auto;
        top: 41rem;
        display: flex;
        z-index: 25;
    }
    
    .BackCon{
        right: 0vw;
        top: 12.5rem;
    }
    
    .BackCon img{
        width: 12rem;
    }
}