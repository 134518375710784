.Container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
    width: 85vw;
    position: relative;
    border-radius: 30px;
    /* border: 3px solid black; */
    background-color: #efefef;
    /* background-color: rgba(245,255,70,0.5); */
    padding: 40px 0;
}

.box, .boxOpen{
    display: flex;
    flex-direction: column;
    /* border: 1px solid black; */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    height: 50px;
    overflow: hidden;
    transition: height 0.3s ease-in;
    margin: 0 5%;
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
}

.boxOpen{
   height: 110px;
}

.upperCon{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    display: flex;
    align-items: center;
    font-weight: 600;
    padding: 10px 20px;
}

.logoCon{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upperCon img{
    width: 25px;
    cursor: pointer;
}

.lowerCon{
    margin-top: 0px;
    font-size: 15px;
    padding: 10px 20px;
}

@media (max-width: 990px) {
    .Container{
        gap: 20px;
        width: 95vw;
        border-radius: 10px;
        padding: 30px 0;
    }
    
    .box, .boxOpen{
        height: 65px;
        margin: 0 3%;
        border-radius: 10px;
    }
    
    .boxOpen{
       height: 180px;
    }
    
    .upperCon{
        font-size: 16px;
        padding: 7px 15px;
    }

    .logoCon{
        height: 30px;
    }
    
    .upperCon img{
        width: 15px;
    }
    
    .lowerCon{
        margin-top: 15px;
        font-size: 13px;
        padding: 7px 15px;
    }
}