.container {
    height: auto;
    width: 85vw;
    overflow: hidden;
    position: relative;
    border-radius: 30px;
    border: 3px solid black;
    /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); */
}

.Title{
    text-align: center;
    font-size: 30px;
    margin-top: 40px;
    margin-bottom: 50px;
}

.lineCon{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 55px 15vw;
}

.step{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15vw;
}

.iconCon img{
    width: 60px;
}

.name{
    margin-top: 10px;
    font-size: 15px;
    font-weight: 600;
}

@media (max-width: 990px) {
    .container {
        width: 95vw;
        border-radius: 20px;
        border: 2px solid black;
    }
    
    .Title{
        font-size: 25px;
        margin-top: 30px;
        margin-bottom: 40px;
    }
    
    .lineCon{
        margin: 35px 3vw;
    }
    
    .step{
        width: 23vw;
    }
    
    .iconCon img{
        width: 40px;
    }
    
    .name{
        margin-top: 5px;
        font-size: 13px;
        text-align: center;
    }
}