.container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
    width: 85vw;
    position: relative;
    border-radius: 30px;
    padding: 40px 0 50px;
    /* box-shadow: 0 0 8px rgb(0, 0, 0, 0.3); */
    /* border: 3px solid black; */
    /* background-color: rgba(245,255,70,0.5); */
    /* background-color: rgb(255, 255, 248); */
}

.form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.title{
    font-size: 30px;
    font-weight: 600;
}

.subtitle{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
}

.divCon{
    display: flex;
    gap: 2vw;
}
.fieldCon{
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.fieldCon span{
    font-size: 15px;
}

.form input{
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 15px;
    color: black;
    width: 25vw;
}

.form textarea{
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    color: black;
    width: 55vw;
}

.btn{
    font-size: 16px;
    margin-top: 20px;
    width: 200px !important;
    font-weight: 600;
    cursor: pointer;
    color: white !important;
    background-color: black;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px 20px;
}

@media (max-width: 990px) {
    .container{
        gap: 20px;
        width: 95vw;
        border-radius: 10px;
        padding: 30px 0 40px;
    }
    
    .form{
        gap: 1vh;
    }
    
    .title{
        font-size: 25px;
    }
    
    .subtitle{
        font-size: 18px;
        margin: 0 5% 15px;
    }
    
    .divCon{
        flex-direction: column;
        gap: 1vh;
    }
    
    .fieldCon span{
        font-size: 13px;
    }
    
    .form input{
        padding: 10px 20px;
        font-size: 13px;
        width: 70vw;
    }
    
    .form textarea{
        padding: 10px 20px;
        font-size: 13px;
        width: 70vw;
    }
    
    .btn{
        font-size: 14px;
        margin-top: 20px;
    }
}