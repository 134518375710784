.container{
    display: flex;
    margin: 20vh 10%;
    gap: 5vw;
}
.con{
    width: 40%;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: rgba(239, 239, 239)
}

.upperCon, .upperCon2{
    background-color: white;
    border-radius: 10px 10px 0 0;
}
.upperCon2{
    background-color: black;
}
.packageName , .packageName2{
    font-size: 30px;
    margin-left: 50px;
    margin-top: 30px;
    margin-bottom: 0;
}

.packageName2{
    color: white;
}

.price, .price2{
    font-size: 30px;
    margin-left: 50px;
    margin-top: 10px;
    margin-bottom: 0;
}

.price2{
    color: white;
}

.subtitle, .subtitle2{
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 0px;
    font-size: 15px;
    font-weight: 500;
}

.subtitle2{
    color: white;
}
.btnCon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 20px;
}
.btn , .btn2{
    border: 1px solid black;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 25%;
    border-radius: 10px;
    color: white;
    background-color: black;
    cursor: pointer;
}

.btn2{
    color: black;
    background-color: white;
    border: 1px solid white;
}


.lowerCon{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 20px 30px 25px;
    border-radius: 0 0 10px 10px;
}

.lc3{
    height: 100%;
    background-color: rgba(255, 204, 221,1);
}

.lc2{
    background-color: rgba(239, 239, 239);
}

.lc1{
    background-color:rgba(239, 239, 239);
}

.item{
    display: flex;
    align-items: center;
}

.item img{
    margin-right: 10px;
}

.item span{
    font-size: 15px;
}

@media (max-width: 990px) {
    .container{
        flex-direction: column;
        margin: 0vh 2.5vw;
        gap: 5vh;
    }
    .con{
        width: 95vw;
    }
    .packageName , .packageName2{
        font-size: 25px;
        margin-left: 40px;
    }
    
    
    .price, .price2{
        font-size: 25px;
        margin-left: 40px;
    }
    
    .subtitle, .subtitle2{
        margin-left: 40px;
        margin-right: 40px;
        font-size: 13px;
    }
    .btn , .btn2{
        font-size: 14px;
        padding: 4% 25%;
    }
    
    
    .lowerCon{
        gap: 5px;
    }
    
    .item img{
        margin-right: 10px;
    }
    
    .item span{
        font-size: 13px;
    }
    
}