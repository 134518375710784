.Container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 80px;
    background-color: transparent !important;
    align-items: center;
}
.secondCon{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    font-size: 16px;
    font-weight: 600;
    gap: 20px;
}

.secondCon a{
    color: black;
    text-decoration: none;
    cursor: pointer;
    z-index: 5;
}

.secondCon button{
    color: black;
    text-decoration: none;
    cursor: pointer;
    z-index: 5;
    border: none;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
}

.logoImg{
    width: 120px;
    z-index: 5;
}


.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .serviceTitle {
    cursor: pointer;
    padding: 10px 0;
    text-decoration: none;
    background-color: transparent;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .services {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    border-radius: 5px;
    z-index: 1;
    padding: 10px 0;
  }
  
  .services a {
    padding: 10px 15px;
    text-decoration: none;
    color: #333;
    white-space: nowrap;
    padding: 10px 30px;
  }
  
  .services a:hover {
    background-color: #f0f0f0;
  }


  .secondConMobile{
    display: none;
}
  

@media (max-width: 990px) {
    .Container{
        padding: 20px 20px;
        align-items: center;
    }
    
    .logoImg{
        width: 70px;
    }
    .secondCon{
        display: none;
    }

    .secondConMobile{
        display: flex;
        position: relative;
    }

    .menuIcon img{
        width: 35px;
    }

    .dropdownMobile{
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: 35px;
        padding: 0 20px;
        background-color: white;
        z-index: 100;
        border-radius: 5px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    }

    .dropdownMobile a{
        text-decoration: none;
        color: black;
        font-size: 14px;
        padding: 10px 20px 10px 5px;
        cursor: pointer;
    }

    .dropdownMobile button{
        text-decoration: none;
        color: black;
        font-size: 14px;
        padding: 10px 20px 10px 5px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        text-align: start;
    }
}