.container {
    height: auto;
    width: 85vw;
    overflow: hidden;
    position: relative;
    border-radius: 30px;
    border: 3px solid black;
    /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); */
}

.Title{
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-top: 40px;
}

.subTitle{
    text-align: center;
    font-size: 20px;
    margin: 0 12%;
    font-weight: 500;
    margin-top: 3vh;
}

.mainCon{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4% 5% 0;
    margin-bottom: 60px;
}

.con{
    /* border: 3px solid black; */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    width: 30%;
    border-radius: 20px;
    background-color: white;
}

.upper{
    display: none;
    border-radius: 17px 17px 0 0;
    height: 30px;
    border-bottom: 2px solid black;
}

.upper img{
    margin-left: 20px;
    width: 50px;
    height: 100%;
    object-fit: cover;
}

.logoCon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.logoCon img{ 
    width: 50px;
}

.heading{
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
    font-weight: 700;
}

.para{
    height: 17%;
    margin-top: 25px;
    text-align: center;
    margin: 15px 30px 0;
    font-weight: 500;
    font-size: 15px;
}

.btnCon{
    display: flex;
    justify-content: center;
    margin: 40px auto 25px;
}

.btn{
    border: 2px solid black;
    background-color: black;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 40px;
    cursor: pointer;
    color: white;
}

.btn:hover{
    box-shadow: 0 0 8px rgba(0, 0, 0,0.7);
}

@media (max-width: 990px) {
    .container {
        width: 95vw;
        border-radius: 10px;
        border: 2px solid black;
    }
    
    .Title{
        font-size: 25px;
        margin-top: 30px;
    }
    
    .subTitle{
        font-size: 18px;
        margin: 0 5%;
        margin-top: 10px;
    }
    
    .mainCon{
        flex-direction: column;
        justify-content: space-between;
        padding: 10% 5% 0;
        gap: 5vh;
        margin-bottom: 45px;
    }
    
    .con{
        width: 100%;
        border-radius: 20px;
    }
    
    .upper{
        border-bottom: 1px solid black;
    }
    
    .upper img{
        width: 40px;
    }
    
    .logoCon{
        margin-top: 15px;
    }
    
    .logoCon img{ 
        width: 50px;
    }
    
    .heading{
        font-size: 18px;
        margin-top: 12px;
    }
    
    .para{
        height: 17%;
        margin: 15px 30px 0;
        font-size: 13px;
    }
    
    .btnCon{
        margin: 20px auto;
    }
    
    .btn{
        border: 1px solid black;
        font-size: 14px;
    }
    
    .btn:hover{
        border: 1px solid white;
    }
}