.container{
    background-color: transparent !important;
}
.background{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 75vh;
    width: 100vw;
    overflow: hidden;
    background: linear-gradient(180deg, rgb(188, 188, 188), rgb(255,255,255));
}
.heading{
    text-align: center;
    font-size: 30px;
    margin: 5rem 15vw 0;
    z-index: 5 !important;
}

.title{
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    margin: 1rem 0 0;
    z-index: 5 !important;
}
  
.subTitle{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin: 3rem 18vw 0;
    z-index: 5 !important;
}

.formCon{
    margin-top: 20vh;
    margin-bottom: 20vh;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
    width: 70vw;
    margin-left: 15vw;
    border-radius: 20px;
    padding: 50px 0;
}


.form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.formtitle{
    font-size: 30px;
    font-weight: 600;
}

.formtitle2{
    font-size: 30px;
    font-weight: 500;
    margin-top: 10vh;
    margin-bottom: 2vh;
}

.formSubTitle{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
}

.divCon{
    display: flex;
    gap: 2vw;
}
.fieldCon{
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.fieldCon span{
    font-size: 15px;
}

.form input{
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 15px;
    color: black;
    width: 20vw;
}

.form textarea{
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    color: black;
    width: 45vw;
}

.form select{
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 15px;
    color: black;
    width: 22.7vw;
}

.btn{
    font-size: 16px;
    margin-top: 20px;
    width: 200px !important;
    font-weight: 600;
    cursor: pointer;
    color: white !important;
    background-color: black;
}

.btn:hover{
    background-color: white;
    color: black !important;
}




.checkboxCon{
    display: flex;
    flex-wrap: wrap;
    margin: 0 15%;
    justify-content: space-between;
    gap: 15px;
}

.checkboxCon .label{
    display: flex;
    width: 20vw;
}

.checkboxCon .label input{
    width: 50px;
}