.firstCon{
    margin-top: 10vh;
}
.secondCon{
    margin-top: 10vh;
}

.thirdCon{
    margin-top: 10vh;
}

.footerCon{
    margin-top: 20vh;
}

@media (max-width: 990px) {
    .firstCon{
        margin-top: 5vh;
    }
    .secondCon{
        margin-top: 12vh;
    }
    
    .thirdCon{
        margin-top: 12vh;
    }
    
    .footerCon{
        margin-top: 15vh;
    }
}