.container{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    height: auto;
    min-height: 100vh;
    
}
.innerCon{
    border: 3px solid black;
    width: 25vw;
    height: auto;
    margin: 10vh 0;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(255, 255, 255,0.1);
}

.logoCon{
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoCon img{
    width: 150px;
    margin-top: 3vh;
}

.plan{
    margin-top: 5vh;
    margin-left: 1.25vw;
    font-size: 15px;
}

.packageDetails{
    border: 1px solid black;
    width: 20vw;
    margin-left: 1.1vw;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 5px;
}

.packageName{
    font-size: 20px;
    font-weight: 600;
}

.amount{
    font-size: 15px;
    font-weight: 600;
}

.infoCon{
    display: flex;
    align-items: center;
    margin-top: 5vh;
}

.form{
    width: 25vw;
    display: flex;
    flex-direction: column;
    gap: 3vh;
    align-items: center;
}

.infoDiv{
    display: flex;
    flex-direction: column;
}

.infoDiv span{
    font-size: 15px;
}

.infoDiv input{
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 15px;
    color: black;
    width: 20vw;
}

.btn{
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: black;
    width: auto;
    margin: 2.5vh 0 5vh;
    cursor: pointer;
}

@media (max-width: 990px) {
    .innerCon{
        border: 2px solid black;
        width: 95vw;
    }
    
    .logoCon img{
        width: 120px;
    }
    
    .plan{
        margin-top: 5vh;
        margin-left: 2.25vw;
    }
    
    .packageDetails{
        width: 82vw;
        margin-left: 1.1vw;
    }
    
    .packageName{
        font-size: 18px;
    }
    
    .amount{
        font-size: 14px;
    }
    
    .infoCon{
        margin-top: 4vh;
    }
    
    .form{
        width: 100%;
        gap: 2vh;
    }
    
    .infoDiv{
        display: flex;
        flex-direction: column;
    }
    
    .infoDiv input{
        width: 80vw;
    }
    
    .btn{
        font-size: 14px;
    }
}