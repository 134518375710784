.background3{
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  z-index: -1;
  height: 300vh;
  width: 100vw;
}
.firstDiv{
  background-color: transparent !important;
  z-index: 5 !important;
}

.heading{
  text-align: center;
  font-size: 30px;
  margin: 5rem 15vw 0;
  z-index: 5 !important;
}
      .title{
        font-size: 45px;
        text-align: center;
        margin: 1rem 12vw 0;
        z-index: 5 !important;
      }

      .subTitle{
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        margin: 3rem 18vw 0;
        z-index: 5 !important;
      }

      .btnDiv{
        display: flex;
        justify-content: center;
        margin-top: 7vh;
        z-index: 5 !important;
      }

      .contactBtn{
        text-align: center;
        background-color: black;
        border: 1px solid black;
        color: white;
        padding: 8px 50px;
        font-size: 16px;
        border-radius: 10px;
        cursor: pointer;
      }

      .howweworkTitle{
        text-align: center;
        font-size: 30px;
        margin-top: 30vh;
        margin-bottom: -12vh;
        font-weight: 700;
      }

      .secondDiv{
        padding: 0 22%; 
      }

      .thirdDiv{
        margin-top: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }

.whatweDoDiv{
        margin-top: 20vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
}


.recentWorkDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
  position: relative;
}

.testimonialDiv{
  margin-top: 5vh;
  position: relative;
}

.packagesDiv{
  margin-top: 20vh;
  position: relative;
}

.adtionalServiceDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
  position: relative;
}

.faqDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
}
.contactCon{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 13vh;
}
.footerCon{
  margin-top: 15vh;
}



/* -----------shapes styling ----------------- */

.whatweDoShape{
  position: absolute;
  z-index: 50 !important;
  top: -2vh;
  left: 8vw;

}

.whatweDoShape img{
  width: 120px;
}

.recentworkShape2{
  position: absolute;
  z-index: 50 !important;
  bottom: -5vh;
  left: -1vw;

}

.recentworkShape2 img{
  width: 150px;
}

.testimonialShape{
  position: absolute;
  z-index: 50 !important;
  bottom: -5vh;
  right: -1vw;

}

.testimonialShape img{
  width: 150px;
}

.industyShape{
  position: absolute;
  z-index: 50 !important;
  top: -5vh;
  right: 3vw;

}

.industyShape img{
  width: 150px;
}

.additionalShape{
  position: absolute;
  z-index: 50 !important;
  top: -7vh;
  right: 4vw;

}

.additionalShape img{
  width: 150px;
}

.packageShape{
  position: absolute;
  z-index: 50 !important;
  top: -2.5vh;
  right: 19vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  background-color: rgb(255,191,35);
  color: black;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: 700;
}

@media (max-width: 990px) {
  .background3{
    height: 150vh;
    width: 100vw;
  }
  
  .heading{
    font-size: 25px;
    margin: 3rem 0 0;
  }
  
  .title{
    font-size: 35px;
    margin: 1rem 5vw 0;
  }
  
  .subTitle{
    font-size: 18px;
    margin: 2rem 5vw 0;
  }
  
  .btnDiv{
    margin-top: 4vh;
  }
  
  .contactBtn{
    padding: 8px 50px;
    font-size: 14px;
  }
  
  .howweworkTitle{
    font-size: 25px;
    margin-top: 15vh;
    margin-bottom: -12vh;
  }
  .secondDiv{
    padding: 0 10%; 
  }

  .thirdDiv{
    margin-top: 28vh;
  }

.whatweDoDiv{
    margin-top: 15vh;
}


.recentWorkDiv{
margin-top: 10vh;
}

.testimonialDiv{
margin-top: 10vh;
}

.packagesDiv{
margin-top: 15vh;
}

.adtionalServiceDiv{
margin-top: 15vh;
}

.faqDiv{
margin-top: 15vh;
}
.contactCon{
margin-top: 10vh;
}
.footerCon{
margin-top: 10vh;
}



/* -----------shapes styling ----------------- */

.whatweDoShape{
top: -4vh;
left: 2vw;

}

.whatweDoShape img{
width: 70px;
}

.recentworkShape2{
display: none;
}

.testimonialShape{
display: none;

}

.industyShape{
top: -5vh;
right: 2vw;

}

.industyShape img{
width: 100px;
}

.additionalShape{
top: -5vh;
right: 0vw;

}

.additionalShape img{
width: 80px;
}

.packageShape{
display: none;
top: auto;
bottom: 38rem;
right: 40vw;
align-items: center;
justify-content: center;
border: 1px solid black;
background-color: rgb(255,191,35);
color: black;
padding: 5px 10px;
font-size: 15px;
font-weight: 700;
}
}
