.container{
    display: flex;
    position: relative;
}

.workCon{
    display: flex;
    width: 97vw;
}
.background{
    width: 40%;
    height: 90vh;
    margin-left: -20px;
    border-radius: 0 20px 20px 0;
}

.Img{
    width: 25vw;
    object-fit: cover;
    border-radius: 20px;
    border: 1px solid lightgrey;
}

    /* background-color: rgba(62, 156, 245, 1); */


.workCon2{
    display: flex;
    position: absolute;
    width: 70vw;
    justify-content: space-between;
    left: 23vw;
    top: 20vh;
}

.workCon2 img{
    border: 3px solid black;
}

.descriptionCon{
    width: 60%;
    display: flex;
    align-items: center;
}

.details{
    margin-left: 22vw;
    width: 30vw;
}

.title{
    font-size: 30px;
    font-weight: 700;
}

.para{
    margin-top: 3vh;
    font-size: 15px;
}



@media (max-width: 990px) {

    .container{
       /* margin-top: 10rem; */
    }
    .workCon{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 100vw;
    }

    .background{
        width: 100%;
        height: 20rem;
        margin: 0;
        border-radius: 20px 20px 0 0;
    }
    
    .Img{
        width: 80vw;
    }
    .workCon2{
        left: 10vw;
        top: auto;
        bottom: 5rem;
    }
    
    .descriptionCon{
        width: 100%;
        margin-bottom: 15vh;
    }
    
    .details{
        margin: 0;
        width: 95%;
        margin-left: 2.5%;
    }
    
    .title{
        font-size: 25px;
        text-align: center;
    }
    
    .para{
        text-align: center;
        font-size: 13px;
    }
}