.container{
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    color: white;
    padding: 3% 0;
    font-size: 16px;
}

.upper{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* gap: 10px; */
    font-size: 15px;
}

.logo{
    width: 80px;
    height: auto;
}

.lower{
    margin-top: 2%;
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-around;
}

.lower a{
    text-decoration: none;
    color: white;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
}

.lower button{
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.lower a:hover{
    text-decoration: underline;
}

.lower button:hover{
  text-decoration: underline;
}

.email{
    margin-top: 2%;
    font-size: 15px;
}

.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .serviceTitle {
    cursor: pointer;
    padding: 10px 15px;
    text-decoration: none;
    background-color: transparent;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .services {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1;
    padding: 10px 0;
    bottom: 100%;
    margin-bottom: 5px;
    left: 0;
  }
  
  .services a {
    padding: 10px 15px;
    text-decoration: none;
    color: #333;
    white-space: nowrap;
    padding: 10px 30px;
  }
  
  .services a:hover {
    background-color: #f0f0f0;
    text-decoration: none;
  }

@media (max-width: 990px) {
    .container{
        padding: 3% 0;
    }
    
    .upper{
        font-size: 13px;
    }
    
    .logo{
        width: 60px;
        margin-bottom: 5%;
    }
    
    .lower{
        margin-top: 2%;
        display: flex;
        width: 80%;
        flex-wrap: wrap;
    }
    
    .lower a{
        font-size: 13px;
        width: 50%;
        text-align: center;
    }

    .lower button{
      font-size: 13px;
      width: 50%;
      text-align: center;
  }
    
    .email{
        margin-top: 3%;
        font-size: 13px;
    }

    .dropdown {
        width: 45%;
        position: relative;
        display: inline-block;
      }

      
      .serviceTitle {    
        display: flex;
        height: 0px;
        align-items: center;
        width: 100% !important;
        justify-content: center; 
        text-align: start;
      }
      
      .services {
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
        right: 0;
        left: auto;
      }
      
      .services a {
        padding: 10px 15px;
        text-decoration: none;
        color: #333;
        white-space: nowrap;
        padding: 10px 30px;
      }
      
      .services a:hover {
        background-color: #f0f0f0;
        text-decoration: none;
      }
}