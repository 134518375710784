.container{
  display: flex;
  align-items: center;
  position: relative;
  background-color: transparent !important;
}

.strightLine {
    height: 1px;
    width: 0;
    background-color: black;
    transition: width 3s ease;
  }
  
  .startLine {
    width: 600px;
    margin-top: 300px;
  }
  

  .planningLine {
    position: absolute;
    height: 0vh;
    width: 0.9px;
    bottom: 0px;
    left: 220px;
    background-color: black;
    transition: height 0.5s ease, bottom 0s ease;
    transition-delay: 0.5s;
}

.startPlanningLine {
    height: 100px;
    bottom: 46px;
}

.designLine{
    position: absolute;
    height: 0px;
    width: 0.9px;
    top: 346px;
    left: 340px;
    background-color: black;
    transition: height 0.5s ease;
    transition-delay: 1s;
  }

  .startDesignLine{
    height: 100px;
  } 

.developLine {
    position: absolute;
    height: 0vh;
    width: 0.9px;
    bottom: 0px;
    left: 460px;
    background-color: black;
    transition: height 0.5s ease, bottom 0s ease;
    transition-delay: 1.5s;
}

.startDevelopLine {
    height: 100px;
    bottom: 46px;
}

.testingLine{
    position: absolute;
    height: 0px;
    width: 0.9px;
    top: 346px;
    left: 580px;
    background-color: black;
    transition: height 0.5s ease;
    transition-delay: 2s;
  }

  .startTestingLine{
    height: 100px;
  }




  .startPoint{
    border: 1px solid black;
    border-radius: 10px;
    width: 95px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 300px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    padding: 5px;
  }

  .Img{
    margin-top: 10px;
    width: 40px;

  }

  .startPoint p{
    font-size: 15px;
    font-weight: 600;
    margin-top: 5px;
  }

  .endPoint{
    opacity: 0;
    transition: opacity 0.5s ease;
    transition-delay: 2.6s;
  }

  .aniEndPoint{
    opacity: 100;
  }

  .designPoint{
    position: absolute;
    bottom: 146px;
    left: 170px;
    opacity: 0;
    transition: opacity 0.5s ease;
    transition-delay: 1s;
  }

  .aniDesignPoint{
    opacity: 100;
  }

  .contentPoint{
    position: absolute;
    bottom: 146px;
    left: 410px;
    opacity: 0;
    transition: opacity 0.5s ease;
    transition-delay: 2s;
  }

  .aniContentPoint{
    opacity: 100;
  }

  .developmentPoint{
    position: absolute;
    top: 146px;
    left: 290px;
    opacity: 0;
    transition: opacity 0.5s ease;
    transition-delay: 1.5s;
  }

  .aniDevelopmentPoint{
    opacity: 100;
  }

  .testingPoint{
    position: absolute;
    top: 146px;
    left: 530px;
    opacity: 0;
    transition: opacity 0.5s ease;
    transition-delay: 2.3s;
  }

  .aniTestingPoint{
    opacity: 100;
  }


  @media (max-width: 750px) {
      .startLine {
        width: 200px;
        margin-top: 250px;
      }
    
      .planningLine {
        left: 95px;
    }
    
    .startPlanningLine {
        height: 70px;
        bottom: 28px;
    }
    
    .designLine{
        top: 279px;
        left: 135px;
      }
    
      .startDesignLine{
        height: 70px;
      } 
    
    .developLine {
        left: 177px;
    }
    
    .startDevelopLine {
        height: 70px;
        bottom: 28px;
    }
    
    .testingLine{
        top: 279px;
        left: 217px;
      }
    
      .startTestingLine{
        height: 70px;
      }
    
      .startPoint{
        border-radius: 5px;
        width: 50px;
        height: 50px;
        margin-top: 250px;
        padding: 3px;
      }
    
      .Img{
        margin-top: 2px;
        width: 30px;
      }
    
      .startPoint p{
        font-size: 10px;
        margin-top: 1px;
      }
    
      .designPoint{
        bottom: 98px;
        left: 66px;
      }
    
      .contentPoint{
        bottom: 98px;
        left: 150px;
      }
    
      .developmentPoint{
        top: 98px;
        left: 105px;
      }
    
      .testingPoint{
        top: 98px;
        left: 188px;
      }
}