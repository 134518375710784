.container{
    background-color: transparent !important;
}
.background{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 75vh;
    width: 100vw;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(255, 238, 204,1), rgb(255,255,255));
}
.heading{
    text-align: center;
    font-size: 30px;
    margin: 5rem 15vw 0;
    z-index: 5 !important;
}

.title{
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    margin: 1rem 10vw 0;
    z-index: 5 !important;
}
  
.subTitle{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin: 3rem 18vw 0;
    z-index: 5 !important;
}

.btnDiv{
    display: flex;
    justify-content: center;
    margin-top: 7vh;
    z-index: 5 !important;
  }

  .contactBtn{
    text-align: center;
    background-color: black;
    border: 1px solid black;
    color: white;
    padding: 8px 50px;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
  }

.vector1Con{
    display: flex;
    margin-top: 10vh;
}

.vector1left{
    width: 50%;
    padding: 0% 4% 0% 8%;
}

.vector1left img{
    height: auto;
    width: 80%;
}

.vector1right{
    width: 50%;
    padding: 0% 8% 0% 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.question{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 3vh;
}

.ans{
    font-size: 20px;
    font-weight: 500;
}


.vector2Con{
    display: flex;
    margin-top: -10vh;

}

.vector2right{
    width: 50%;
    padding: 0% 8% 0% 4%;
}

.vector2right img{
    height: auto;
    width: 80%;
}

.vector2left{
    width: 50%;
    padding: 0% 4% 0% 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.featureCon{
    display: flex;
    justify-content: center;
    margin: 5vh 0 0;
}

.featureInnerCon{
    width: 85vw;
    border: 3px solid black;
    border-radius: 20px;
    /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.8); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(136, 221, 255,1);
}

.featureTitle{
    font-size: 30px;
    font-weight: 700;
    margin: 40px 0 50px;
}

.featureDivLine{
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 0 0 50px;
}

.featureDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 14vw;
}

.featureLogo img{
    width: 60px;
}

.featureName{
    margin-top: 10px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
}

.featurePara{
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}



.packageCon{
    display: flex;
    gap: 10vw;
    justify-content: center;
    margin-top: 20vh;
    margin-bottom: 20vh;
    position: relative;
}

.packageLeftCon{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.packageleftTitle{
    font-size: 30px;
    font-weight: 700;
}

.packageleftSubtitle{
    margin-top: 2vh;
    font-size: 20px;
    font-weight: 600;
}

.packageleftPara{
    margin-top: 2vh;
    font-size: 15px;
    font-weight: 500;
}

.packageDiv{
    border: 1px solid black;
    width: 24.8%;
    border-radius: 10px;
}

.p2{
    background-color: rgb(255, 204, 221);
}

.packageName{
    font-size: 30px;
    margin-left: 50px;
    margin-bottom: 0;
    font-weight: 700;
    color: white;
}

.packagePrice{
    font-size: 30px;
    margin-left: 50px;
    margin-top: 10px;
    margin-bottom: 0;
    color: white;
}

.packageSubtitle{
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 0px;
    font-size: 15px;
    font-weight: 500;
    color: white;
}

.packageBtnCon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 20px;
}
.packageBtn{
    border: 1px solid black;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 32%;
    border-radius: 10px;
    color: black;
    background-color: white;
    cursor: pointer;
}

.upperPackage{
    background-color: black;
    border-radius: 10px 10px 0 0;
    padding: 30px 0 10px;
}

.lowerPackage{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 20px 30px;
    border-radius: 0 0 10px 10px;
}

.packageItem{
    display: flex;
    align-items: center;
}

.packageItem img{
    margin-right: 10px;
}

.packageItem span{
    font-size: 15px;
}

.packageShape{
    position: absolute;
    z-index: 50 !important;
    top: -2.5vh;
    right: 27vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    background-color: rgb(255,191,35);
    color: black;
    padding: 5px 10px;
    font-size: 15px;
    font-weight: 700;
  }

  .FaqCon{
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 10vh; */
    margin-bottom: 20vh;
  }


  @media (max-width: 990px) {
    .background{
        height: 75vh;
    }

    .heading{
        font-size: 25px;
        margin: 3rem 0 0;
    }
    
    .title{
        font-size: 35px;
        margin: 1rem 5vw 0;
    }
      
    .subTitle{
        font-size: 18px;
        margin: 2rem 5vw 0;
    }
    
    .vector1Con{
        flex-direction: column;
        margin-top: 10vh;
        align-items: center;
    }
    
    .vector1left{
        width: 90%;
        padding: 0;
        padding-left: 10%;
    }
    
    .vector1right{
        width: 95vw;
        padding: 2.5vw;
    }
    
    .question{
        font-size: 25px;
        margin-top: -5vh;
        margin-bottom: 2vh;
        text-align: center;
    }
    
    .ans{
        font-size: 18px;
        text-align: center;
    }
    
    
    .vector2Con{
        flex-direction: column-reverse;
        margin-top: 7vh;
    }
    
    .vector2right{
        width: 90%;
        padding: 0;
        padding-left: 10%;
    }
    
    .vector2left{
        width: 95vw;
        padding: 2.5vw;
    }

    .featureCon{
        margin: 13vh 0 0;
    }
    
    .featureInnerCon{
        width: 95vw;
        border: 2px solid black;
        border-radius: 10px;
    }
    
    .featureTitle{
        font-size: 25px;
        text-align: center;
        margin: 30px 0 40px;
    }
    
    .featureDivLine{
        width: 90%;
        margin: 0 0 40px;
    }
    
    .featureDiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25vw;
    }
    
    .featureLogo img{
        width: 40px;
    }
    
    .featureName{
        margin-top: 8px;
        font-size: 13px;
    }
    
    .featurePara{
        font-size: 13px;
    }

    
.packageCon{
    flex-direction: column;
    gap: 10vw;
    margin-top: 15vh;
    margin-bottom: 15vh;
}

.packageLeftCon{
    width: 95vw;
    margin-left: 2.5vw;
}


.packageleftTitle{
    font-size: 25px;
    text-align: center;
}

.packageleftSubtitle{
    margin-top: 2vh;
    font-size: 18px;
    text-align: center;
}

.packageleftPara{
    margin-top: 2vh;
    font-size: 13px;
    text-align: center;
}

.packageDiv{
    width: 95vw;
    margin-left: 2.5vw;
}

.packageName{
    font-size: 25px;
    margin-left: 40px;
}

.packagePrice{
    font-size: 25px;
    margin-left: 40px;
    margin-top: 8px;
}

.packageSubtitle{
    margin-left: 40px;
    margin-top: 5px;
    font-size: 13px;
}

.packageBtn{
    font-size: 14px;
    padding: 4% 30%;
}

.upperPackage{
    padding: 20px 0 10px;
}

.packageItem span{
    font-size: 13px;
}

.packageShape{
    display: none;
  }

  .FaqCon{
    margin-bottom: 15vh;
  }

  }