@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
/* In your styles or imported stylesheet */

*{
  font-family: "Open Sans", sans-serif;
}

body{
  overflow-x: hidden;
}

#ddlProducts *
{
 border-radius: 15px;
 background-color: red;
}

@media (max-width: 990px) {
  body{
    width: 100vw;
    overflow-x: hidden;
  }
}