.container {
    height: 70vh;
    width: 85vw;
    overflow: hidden;
    position: relative;
    /* border: 3px solid black; */
    border-radius: 30px;
    background-color: #efefef;
    /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); */
  }

  .listCon{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .titleCon{
    height: 100%;
    width: 35vw;
    display: flex;
    flex-direction: column;
    margin-left: 10vw;
  }

  .titleCon h1{
    margin-top: 20vh;
    font-size: 30px;
    font-weight: 700;
  }

  .titleCon p{
    margin-top: 3vh;
    font-size: 20px;
  }
  
  .list {
    position: absolute;
    right: 170px;
    animation: scroll-up 55s linear infinite;
  }
  
  .listItem {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 40px 0;
    color: black;
    width: auto;
  }

  .span{
    height: 15px;
    width: 15px;
    border-radius: 50%;
    position: absolute;
    left: 45px;
  }
  
  .name{
    font-size: 20px;
    border: 1px solid rgb(210, 210, 210);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    padding: 10px 20px 10px 40px;
    border-radius: 50px;
    margin: 40px 30px;
    background-color: white;
  }
  @keyframes scroll-up {
    0% {
      top: 0%;
    }
    100% {
      top: -1000%;
    }}


@media (max-width: 990px) {
  .container {
    height: 100vh;
    width: 95vw;
    border-radius: 20px;
  }

  .listCon{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .titleCon{
    height: 45vh;
    width: 80vw;
    margin-left: 5vw;
    overflow: hidden;
    background-color: #efefef;
    z-index: 1;
  }

  .titleCon h1{
    margin-top: 2vh;
    font-size: 25px;
  }

  .titleCon p{
    margin-top: 3vh;
    font-size: 18px;
  }
  
  .list {
    right: 10vw;
  }
  
  .listItem {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 40px 0;
    color: black;
    width: auto;
  }

  .span{
    left: 45px;
  }
  
  .name{
    font-size: 18px;
  }
}