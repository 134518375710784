.container{
    display: flex;
    position: relative;
}

.workCon{
    display: flex;
    width: 97vw;
}
.background{
    width: 40%;
    height: 90vh;
    margin-left: -20px;
    border-radius: 0 20px 20px 0;
}

.Img{
    width: 25vw;
    height: 30vh;
    border: 3px solid black !important;
    object-fit: cover;
    border-radius: 20px;
    border: 1px solid lightgrey;
}

    /* background-color: rgba(62, 156, 245, 1); */


.workCon2{
    display: flex;
    position: absolute;
    width: 70vw;
    justify-content: space-between;
    left: 23vw;
    top: 10vh;
}

.workCon3{
    display: flex;
    position: absolute;
    width: 70vw;
    justify-content: space-between;
    left: 23vw;
    top: 49vh;
}

.descriptionCon{
    width: 60%;
    display: flex;
    align-items: center;
}

.details{
    margin-left: 22vw;
    width: 30vw;
}

.title{
    font-size: 30px;
    font-weight: 700;
}

.subtitle{
    margin-top: 3vh;
    font-size: 20px;
    font-weight: 700;
}

.para{
    margin-top: 3vh;
    font-size: 20px;
}

@media (max-width: 990px) {
    .workCon{
        display: flex;
        flex-direction: column-reverse;
        width: 100vw;
    }

    .background{
        width: 100%;
        height: 25rem;
        margin: 0;
        margin-top: 10rem;
        border-radius: 20px 20px 0 0;
    }
    
    .Img{
        width: 100%;
        border-radius: 10px;
    }
    
    .workCon2{
        width: 80vw;
        left: 10vw;
        top: 19rem;
    }
    
    .workCon3{
        width: 80vw;
        left: 10vw;
        top: 35rem;
    }
    
    .descriptionCon{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .details{
        margin: 0;
        width: 95vw;
    }
    
    .title{
        font-size: 25px;
        text-align: center;
    }
    
    .subtitle{
        margin-top: 1vh;
        font-size: 18px;
        text-align: center;
    }
    
    .para{
        margin-top: 2vh;
        font-size: 18px;
        text-align: center;
    }
}